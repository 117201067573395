const modalBtn = document.querySelector('#cta-popup-testrit');
const textArea = document.getElementsByName('fields[bericht]')[1];
const form = document.querySelector('#form-contact');

const ctaButtons = document.querySelectorAll('.btn--testrit');

modalBtn.addEventListener('click', function() {
    textArea.value = 'Ik wil graag een testrit boeken.';
});

ctaButtons.forEach((e) => {
    e.addEventListener('click', function() {
        textArea.value = 'Ik wil graag een testrit boeken.';
    });
});
