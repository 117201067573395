const heroCarsBox = document.querySelector('.hero__carsBox');

if (heroCarsBox) {
    document.addEventListener('DOMContentLoaded', function() {
        window.addEventListener('scroll', function() {
            const scrollPosition = window.scrollY;
            heroCarsBox.style.transform = 'translateX(' + (-scrollPosition / 20) + 'px)';
        });
    });
}
