const controls = document.querySelector('#controls');

window.addEventListener('scroll', () => {
    const currentScroll = window.scrollY

    if (currentScroll > 100) {
        controls.classList.add('controls__show');
    } else {
        controls.classList.remove('controls__show');
    };
});
